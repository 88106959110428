<template>
  <div class="drug-register-view view">
    <div class="actions-panel panel"
      style="width: fit-content; display: flex; flex-direction: column;">
      <h3 class="panel-header">Actions</h3>
      <!-- if using carton cloud integration and have a can_do_admin_roles OR company type is aged care, will enable -->
      <button :disabled="cartonCloudRestriction" @click="handleOpenActionModal()" class="actions-menu-item">New {{$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}}</button>
    </div>
    <div class="panel" v-if="$store.state.user.role.permissions.includes('can_view_destruction')">
      <h3 class="panel-header">{{$store.state.company.type == 'warehouse' ?  'Destructions' : 'Disposals'}}</h3>
      <div class="drug-register-table table-container">
        <div class="filters" style="display: flex; flex-wrap: wrap; align-items: center; height: 50px;">
          <!-- SEARCH SECTION -->
          <div style="display: flex; align-items: center; margin-right: 1em">
            <div class="text-search-container search-container">
                  <input v-model="form.filters.drug" @input="debouncedLoadEntries"   placeholder="Medication..." style="position: relative" />
            </div>

            <div class="text-search-container search-container" v-if="$store.state.company.type !== 'warehouse'">
                <input v-model="form.filters.patient" placeholder="Resident..." style="position: relative" @input="debouncedLoadEntries" />
            </div>

            <!-- <div class="date-search-container search-container">
              <Calendar v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" />
            </div> -->
            
            <button class="button submit-button" @click="loadDestructions()">Search</button>
            <button class="button clear-button" @click="clearTransfersFilters()">Clear</button>
          </div>

          <div style="display: flex; align-items: center">
              <div class="select-filters-container">

                <p @click="handlePendingClicked()"
                  v-bind:class="[form.filters.status == 'pending' ? 'active-select-filter' : '']"
                  class="select-filter-item">Pending {{$store.state.company.type == 'warehouse' ?  'Destructions' : 'Disposals'}}</p>

                 <p @click="handleRegisterClicked()"
                  v-bind:class="[form.filters.status == 'completed' ? 'active-select-filter' : '']"
                  class="select-filter-item">{{$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposals'}} Register</p>

            </div>
          </div>

          <!-- DESTROY BUTTON -->
          <div class="select-filters-container" v-if="numberToDestroy > 0 && form.filters.status == 'pending'">
              <p @click="() => { destroyItems() }" class="select-filter-item destroy-button">{{$store.state.company.type == 'warehouse' ?  'Destroy' : 'Dispose'}} {{ numberToDestroy }}
                Items
              </p>
            </div>
            <!-- END DESTROY BUTTON -->
  
            <!-- REVERSE DESTROY BUTTON -->
            <div class="select-filters-container" v-if="numberToReverse > 0">
              <p @click="() => { reverseDestroyItems() }" class="select-filter-item warning-button">Reverse
                {{ numberToReverse }} Items</p>
            </div>
            <!-- END REVERSE DESTROY BUTTON -->

        </div>
        <div v-if="!$store.state.loadingDestructionsData"  style="display: flex; align-items: start; overflow-y: scroll">
          <div class="drug-register-table table-container" style="padding-top: 10em;"
            v-if='!$store.state.user.role.permissions.includes("can_view_destruction")'>
            <h3
              style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
              You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
          </div>
          <table v-else class="data-table" cellspacing="0">
            <tr class="table-headers">
              <th style="width: 25px"></th>
              <th style="width: 50px">
                  <div @click.stop="() => { selectAllItem(numberToDestroy > 0 ? false : true) }" v-if="($store.state.company.type == 'warehouse' || $store.state.currentLocation.state == 'WA')" style="cursor: pointer">
                    <div v-if="allSelected()" class="check-circle circle-on"></div>
                    <div v-else class="circle-off"></div>
                  </div>
                </th> 
              <th style="width: 150px;">
                <h3 @click="sortBy('created_at', 'asc')">Date</h3>
                <div v-if="form.col == 'created_at'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  created_at-sort" v-if="form.col == 'created_at' && form.sort_direction == 'asc'"
                    @click="sortBy('created_at', 'desc')"></i>
                  <i class="pi pi-sort created_at-sort" v-else @click="sortBy('created_at', 'asc')"></i>
                </div>
              </th>
              <th>
                <h3 @click="sortBy('drug', 'desc')">Drug</h3>
                <div v-if="form.col == 'drug'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  drug-sort" v-if="form.col == 'drug' && form.sort_direction == 'asc'"
                    @click="sortBy('drug', 'desc')"></i>
                  <i class="pi pi-sort drug-sort" v-else @click="sortBy('drug', 'asc')"></i>
                </div>
              </th>
              <th v-if="$store.state.company.type == 'warehouse'">
                Batch No.
              </th>
              <th style="width: 100px;">
                <h3 @click="sortBy('qty_sort', 'desc')">Qty</h3>
                <div v-if="form.col == 'qty_sort'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  qty-sort" v-if="form.col == 'qty_sort' && form.sort_direction == 'asc'"
                    @click="sortBy('qty_sort', 'desc')"></i>
                  <i class="pi pi-sort qty-sort" v-else @click="sortBy('qty_sort', 'asc')"></i>
                </div>
              </th>
              <!-- <th style="width: 100px;">
                <h3 @click="sortBy('qty_sort', 'desc')">In</h3>
                <div v-if="form.col == 'qty_sort'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  qty-sort" v-if="form.col == 'qty_sort' && form.sort_direction == 'asc'"
                    @click="sortBy('qty_sort', 'desc')"></i>
                  <i class="pi pi-sort qty-sort" v-else @click="sortBy('qty_sort', 'asc')"></i>
                </div>
              </th> -->
              <!-- <th style="width: 100px;">
                <h3 @click="sortBy('qty_sort', 'desc')">Out</h3>
                <div v-if="form.col == 'qty_sort'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  qty-sort" v-if="form.col == 'qty_sort' && form.sort_direction == 'asc'"
                    @click="sortBy('qty_sort', 'desc')"></i>
                  <i class="pi pi-sort qty-sort" v-else @click="sortBy('qty_sort', 'asc')"></i>
                </div>
              </th> -->
              <th v-if="form.filters.status == 'pending'" style="width: 100px;">
                <h3 @click="sortBy('qty_sort', 'desc')">Total</h3>
                <div v-if="form.col == 'qty_sort'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  qty-sort" v-if="form.col == 'qty_sort' && form.sort_direction == 'asc'"
                    @click="sortBy('qty_sort', 'desc')"></i>
                  <i class="pi pi-sort qty-sort" v-else @click="sortBy('qty_sort', 'asc')"></i>
                </div>
              </th>
              <!-- <th>
                <h3>Notes</h3>
                <div v-if="form.col == 'qty_sort'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  qty-sort" v-if="form.col == 'qty_sort' && form.sort_direction == 'asc'"
                    @click="sortBy('qty_sort', 'desc')"></i>
                  <i class="pi pi-sort qty-sort" v-else @click="sortBy('qty_sort', 'asc')"></i>
                </div>
              </th> -->
              <th style="width: 200px;">
                <h3 @click="sortBy('user_sort', 'desc')">User</h3>
                <div v-if="form.col == 'user_sort'" style="display: inline; cursor: pointer">
                  <i class="pi pi-sort  user-sort" v-if="form.col == 'user_sort' && form.sort_direction == 'asc'"
                    @click="sortBy('user_sort', 'desc')"></i>
                  <i class="pi pi-sort user-sort" v-else @click="sortBy('user_sort', 'asc')"></i>
                </div>
              </th>
            </tr>

            <tbody class="table-data" v-for="(item, index) in getDrugEntries" :key="index">
              <tr :class="[item.display && (item.display == true) ? 'select-row' : '']"
                  @click="showContent(item, index)">
                <td>
                  <i v-if="item.expand && (item.expand == true)" class="pi pi-angle-down"></i>
                  <i v-else class="pi pi-angle-right"></i>
                </td>
                <td>
                  <div @click.stop="() => { selectItem(item, index) }" v-if="($store.state.currentLocation.state != 'WA' || ($store.state.currentLocation.state == 'WA' && (numberToDestroy == 0 || item.display)))" style="cursor: pointer">
                    <div v-if="item.display && (item.display == true)" class="check-circle circle-on"></div>
                    <div v-else class="circle-off"></div>
                  </div>
                </td> 
                <td>{{ item.date }}</td>
                <td v-if="item.alias">{{ item.alias.name }}</td>
                <td v-if="$store.state.company.type == 'warehouse'">{{ item.batch_number ?? 'N/A' }}</td>
                <td>{{ item.quantity }}</td>
                <!-- In -->
                <!-- <td>{{ item.quantity }}</td> -->
                <!-- Out -->
                <!-- <td>{{ item.qty_destroyed ?? 0 }}</td> -->
                <!-- Total -->
                <td v-if="form.filters.status == 'pending'">{{ item.qty_pending ?? 0 }}</td>
                <!-- <td>{{ item.notes }}</td> -->
                <td v-if="item.user && item.user.personal_information">{{ item.user.personal_information.first_name }}
                  {{ item.user.personal_information.last_name }}</td>
              </tr>
              <tr :ref="'expand-' + index" :id="'expand-' + index" class="expanded-content">
                <div class="expanded-transfer-container">
                  <div v-if="!item.expandedInfo" style="margin: 0 auto">
                    <i class="pi pi-spin pi-spinner" style="font-size: 3rem !important; margin: 1.5em 0;"></i>
                  </div>
                    <div v-else class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr; padding: 0;">
                      <div class="transfer-specs-container" v-if="item.expandedInfo">
                      <div class="status-field">
                        <p class="field-title">Type</p>
                        <p v-if="item.expandedInfo && item.expandedInfo.status" class="field-value"
                          style='text-align: center'
                          :class="[item.expandedInfo.status == 'completed' ? 'correct-status-color statuswidth' : (item.expandedInfo.status == 'rejected' || item.expandedInfo.status == 'reversed' ? 'mismatch-status-color statuswidth' : 'mismatch-status-color statuswidth')]">
                          {{$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}}</p>
                      </div>
                      <div class="date-received-field">
                        <p class="field-title">Method</p>
                        <p class="field-value">{{ item.expandedInfo.method ? item.expandedInfo.method : 'N/A' }}</p>
                      </div>
                      <div class="ordered-from-field" v-if="item.expandedInfo.location">
                        <p class="field-title">Location</p>
                        <p class="field-value">{{ item.expandedInfo.location.name }}</p>
                      </div>
                      <div class="ordered-by-field">
                        <p class="field-title">{{ form.filters.status === 'pending' ? "Pending Created By" : "Disposed By" }}</p>
                        <p class="field-value" v-if="item.user && item.user.personal_information">
                          {{ item.user.personal_information.first_name }} {{ item.user.personal_information.last_name }}
                        </p>
                      </div>
                      <div class="fulfilled-by-field">
                        <p class="field-title">Courier Notes</p>
                        <p class="field-value">{{ item.expandedInfo.courier_notes ? item.expandedInfo.courier_notes :
                            'N/A'
                        }}</p>
                      </div>
                      <div class="fulfilled-time-field">
                        <p class="field-title">Courier Name</p>
                        <p class="field-value">{{ item.expandedInfo.courier_name ? item.expandedInfo.courier_name :
                            'N/A'
                        }}</p>
                      </div>
                      <div class="ordered-witness-field">
                        <p class="field-title">Witnessed By</p>
                        <p class="field-value" v-if="item.user && item.user.personal_information">
                          {{ item.witness ?? 'N/A' }}
                        </p>
                      </div>
                      <div class="pending-time-field" v-if="form.filters.status === 'completed'">
                        <p class="field-title">Created At <i>(Pending)</i></p>
                        <p class="field-value">{{ item.pending_user ? item.pending_date : 'N/A' }}</p>
                      </div>
                      <div class="pending-by-field" v-if="form.filters.status === 'completed'">
                        <p class="field-title">Created By <i>(Pending)</i></p>
                        <p class="field-value">{{ item.pending_user ? `${item.pending_user.personal_information.first_name} ${item.pending_user.personal_information.last_name}` : 'N/A' }}</p>
                      </div>
                      <div class="pending-witness-field" v-if="form.filters.status === 'completed'">
                        <p class="field-title">Witness By <i>(Pending)</i></p>
                        <p class="field-value">{{ item.pending_witness ?? 'N/A' }}</p>
                      </div>
                    </div>
                    <div class="transfer-notes-container" style="padding-bottom: 1em;">
                      <h4 class="field-title">Notes</h4>
                      <div class="field-notes" style="width: 95%;">
                        <p>{{ item.notes || item?.expandedInfo?.note }}</p>
                      </div>
                    </div>
                    <div class="transfer-drugs-container" v-if="item.expandedInfo">
                      <p class="field-title">Drugs</p>
                      <div class="drug-field-tables" style="width: 95%; border: 2px solid rgb(182, 182, 182); border-radius: 4px; height: 300px;">
                        <table style="padding: 0px 5px">
                          <tr>
                            <th>Name</th>
                            <th style="width: 100px">Qty</th>
                            <th v-if="$store.state.company.type != 'warehouse'">Resident</th>
                          </tr>
                        </table>
                        <div style="height: 250px; overflow-y: scroll; background-color: white;">
                          <table style="padding-top: 0;">
                            <tr v-for="(drug, key) in item.expandedInfo.drugs" :key="key" style="height: 25px">
                              <td>{{ drug.alias.name }}</td>
                              <td style="width: 100px">{{ drug.quantity + ' ' + drug.alias.form || '-' }}/s</td>
                              <td v-if="$store.state.company.type != 'warehouse'">{{ item.expandedInfo.resident || '-' }}</td>
                              <!-- <td>{{drug.remaining}}</td> -->
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </tr>
            </tbody>

          </table>
        </div>
        <div v-else>
          <h3 v-if="$store.state.destructionError">{{$store.state.destructionError}}</h3>
          <i v-else class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
        <div class="pagination-links" v-if="$store.state.drugEntries && $store.state.drugEntries.current_page">
          <p class="pagination-arrow" v-show="$store.state.drugEntries.current_page > 1"
            @click="displayPage(form.page - 1)"> &larr; </p>
          <p>Page {{ $store.state.drugEntries.current_page }} of {{ $store.state.drugEntries.page_count }}</p>
          <p class="pagination-arrow"
            v-show="$store.state.drugEntries.current_page < $store.state.drugEntries.page_count"
            @click="displayPage($store.state.drugEntries.current_page + 1)"> &rarr; </p>
        </div>
      </div>

    </div>
    <div v-else class="drug-register-table table-container"
      style="padding-top: 5em; height: 78vh; grid-template-rows: [row1-start] 70px [row1-end] minmax(200px, 64vh) [third-line] 50px [fourth-line];">
      <h3
        style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">
        You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <!-- <ActionModal v-if="displayActionModal" @close="close" :toAction="toAction" display="['destructions']" /> -->
    <DestructionModal v-if="displayActionModal" @close="close" @success="isSuccessful" />
    <PendingDestructionModal v-if="displayPendingDestructionModal" @success="isSuccessful" :payload="payload" @close="close" :filterStatus="form.filters.status"/>
    <ReverseDestructionModal v-if="displayReverseDestructionModal" @success="isSuccessful" :payload="payload" @close="close" :filterStatus="form.filters.status"/>
    <SignatureModal v-if="displaySignatureModal" @close="displaySignatureModal = false" :endpoint="getEndpoint()" :dual="true"  :loop="true" :payload="signpayload" @isSuccessful="isSuccessful"/>
  </div>
</template>


<script>
// @ is an alias to /src
// import NewTransfer from '@/components/actions/NewTransfer.vue';
import ReverseDestructionModal from '@/components/modals/ReverseDestructionModal.vue';
import DestructionModal from '@/components/modals/DestructionModal.vue';
import PendingDestructionModal from '@/components/modals/PendingDestructionModal.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import axios from 'axios';
import debounce from "debounce"

export default {
  components: {
    SignatureModal,
    DestructionModal,
    PendingDestructionModal,
    ReverseDestructionModal,
  },
  data() {
    return {
      completedDelivery:0,
      openInvoice:null,
      loadingDestructionEntry: false,
      displaySignatureModal: false,
      displayNewTransferModal: false,
      displayActionModal: false,
      displayPendingDestructionModal: false,
      displayReverseDestructionModal: false,
      toAction: {
        uuid: '',
        action: ''
      },
      selectedStatuses: [
        { status: 'Pending' },
        { status: 'Rejected' },
        { status: 'Completed' }
      ],
      statusSelect: [
        { status: 'Pending' },
        { status: 'Rejected' },
        { status: 'Completed' }
      ],
      payload: {
        items: []
      },
      signpayload:{},
      drugNames: [],
      date: [],
      clearForm: {
        sort_direction: '',
        col: '',
        page: 1,
        location: this.$store.state.uuid,
        filters: {
          status: 'pending',
          drug: '',
          date: {
            start: '',
            end: ''
          },
        }
      },
      form: {
        sort_direction: '',
        col: '',
        page: 1,
        location: this.$store.state.uuid,
        filters: {
          status: 'pending',
          drug: '',
          patient: '',
          date: {
            start: '',
            end: ''
          },
        }
      },
      testData: [
        {
          date: '10/12/2021',
          drug: 'Abilify 5mg',
          form: 'tablet',
          qty: 20,
          notes: 'This is a note',
          user: 'Sarah Galler',
          uuid: 'm230f923f23f00asaaa'
        },
        {
          date: '12/10/2021',
          drug: 'Oxycodone 5mg',
          form: 'tablet',
          qty: 20,
          notes: 'This is a note',
          user: 'Sarah Galler',
          uuid: 'f39f2j3f9egw'
        },
        {
          date: '24/09/2021',
          drug: 'Methadone 5mg',
          form: 'tablet',
          qty: 20,
          notes: 'This is a note',
          user: 'Sarah Galler',
          uuid: 'sdlfsd9gsdf9w'
        },
        {
          date: '02/05/2021',
          drug: 'Panadol 5mg',
          form: 'tablet',
          qty: 20,
          notes: 'This is a note',
          user: 'Sarah Galler',
          uuid: 'sdfksndncam2r22'
        }
      ]

    }
  },
  methods: {
    close(value,value2) {
      if (value) {
        this.displayActionModal = false;
        this.displayNewTransferModal = false;
        this.displaySignatureModal = false;
        this.displayPendingDestructionModal = false;
        this.displayReverseDestructionModal = false;
        this.payload.items = [];
        this.getDrugEntries.forEach(item => {
          item.display = false;
        })
      }
      this.form.filters.status = value2 ?? "pending"
    },
    // SORT DATA BY COLUMN AND ORDER
    sortBy(col, dir) {
      this.form.col = col;
      this.form.sort_direction = dir;
      this.$store.dispatch('getDestructionItems', this.form)
    },
    selectItem(item, index) {
      console.log('this the selected log', item, index)
      // let refid=`expand-${index}`;
      if (this.getDrugEntries[index].display) {
        this.getDrugEntries[index].display = false;
      } else {
        this.getDrugEntries[index].display = true;
      }

    },
    selectAllItem(select){
      const allSelect = this.getDrugEntries.every(entry => entry.display && entry.display == true)
      this.getDrugEntries.forEach(entry=>{
          if(select){
          entry.display = true;
        } else{
          if (allSelect) {
            entry.display = false;
          }else{
            entry.display = true;
          }
        }
      })
    },
    allSelected(){
      if (this.numberToDestroy > 0) {
        const allSelect = this.getDrugEntries.every(entry => entry.display && entry.display == true)
          if (allSelect) {
            return true
          }else {
            return false
          }
      }else{
        return false
      }
    },
    destroyItems() {
      const destroyDrug = [];
    this.getDrugEntries.forEach(item => {
        if (item.display == true) {
            
            destroyDrug.push(item)
        }
    });
    this.payload.items = destroyDrug;
    this.displayPendingDestructionModal = true;
    },
    reverseDestroyItems() {
    const reverseDrug = [];
    this.getDrugEntries.forEach(item => {
      if (item.display == true) {
           
            reverseDrug.push(item)
        }
    });
    this.payload.items = reverseDrug;
    console.log('reverse items function is running', this.payload.items);
    this.displayReverseDestructionModal = true;
   },
   handleOpenActionModal(){
    this.payload = {
        items: []
    },
    this.displayActionModal = true
   },
   handlePendingClicked() {
    this.form.filters.status = 'pending';
    this.loadDestructions();

    if (this.getDrugEntries) {
      this.getDrugEntries.forEach(item => {
        if (item.display == true) {
          item.display = false;
        }
      });
    }
   },
   handleRegisterClicked() {
    this.form.filters.status = 'completed'; 
    this.loadDestructions();

    if (this.getDrugEntries) {
      this.getDrugEntries.forEach(item => {
        if (item.display == true) {
          item.display = false;
        }
      });
    }
   },
    getEndpoint() {
      if (this.displayActionModal) {
        return  '/location/' + this.$store.state.currentLocation.uuid + '/destroy'
      } else if (this.displayReverseDestructionModal) {
        return '/reverse-destruction'
      } else if (this.displayPendingDestructionModal) {
        return '/destroy-pending'
      }
    },
    getButtonName(){
      if (this.displayActionModal) {
        return  `New ${this.$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}`
      } else if (this.displayReverseDestructionModal) {
        return `Reverse ${this.$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}`
      } else if (this.displayPendingDestructionModal) {
        return `Destroy ${this.$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}`
      }
    },
    isSuccessful(value) {
      if (value) {
        this.displayActionModal = false
        this.displayReverseDestructionModal = false
        this.displayPendingDestructionModal = false
        // this.$store.dispatch('getDestructionItems', this.form);
        this.signpayload = {}
      }
      this.displaySignatureModal = false
      // if (value) {
      //   window.location.reload();
      // }
    },
    selectDrugName(name) {
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    loadDestructions() {
      console.log('this is the date', this.date);
      if (this.date.length) {
        if (typeof this.date == "string") {
          this.form.filters.date.start = this.date.split("-")[0]
          this.form.filters.date.end = this.date.split("-")[1]
        } else {
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString();
          this.form.filters.date.end = this.date[1] ? new Date(this.date[1]).toLocaleDateString() : new Date(this.date[0]).toLocaleDateString();
        }
        this.$store.dispatch('getDestructionItems', this.form);
      } else {
        this.form.filters.date.start = '';
        this.form.filters.date.end = '';
        this.$store.dispatch('getDestructionItems', this.form);

      }
      console.log('request: ', this.$store.state.latestDrugEntriesMutationRequest);
    },
    debouncedLoadEntries: debounce(function() {
      if (this.$store.state.company.type == 'warehouse') {
        this.loadDestructions();
      }
    }, 1000),
    displayPage(page) {
      this.form.page = page;
      this.$store.dispatch('getDestructionItems', this.form);
    },
    clearTransfersFilters() {
      this.form = {
        sort_direction: '',
        col: '',
        page: 1,
        location: this.$store.state.uuid,
        filters: {
          status: 'pending',
          drug: '',
          date: {
            start: '',
            end: ''
          },
        }
      };
      this.date = [];
      this.$store.dispatch('getDestructionItems', this.form);
    },
    showContent(destruction, index) {
      console.log('this the selected log', destruction)
      // let refid=`expand-${index}`;

      if (destruction.expand && (destruction.expand === true)) {
        document.getElementById(`expand-${index}`).style.display = 'none';
        destruction.expand = !destruction.expand;
      } else {
        destruction.expand = true;
        console.log('expanding range for this object');
        document.getElementById(`expand-${index}`).style.display = 'table-row';
        axios.post('/single-destruction', { uuid: destruction.uuid }, this.$store.state.header).then(res => {
          console.log('this is the state function to get a single destruction', res.data);
          destruction.expandedInfo = res.data.destruction;
          destruction.expandedInfo.drugs = res.data.destruction.units;
        })
        // destruction.expandedInfo = {
        //     note:'This is a note...',
        //     method:'Burning',
        //     courier_name: 'Fedex',
        //     courier_notes: 'Fedex notes',
        //     location:{
        //       name: "Somewhere",
        //       uuid:"230fk3-dfgdfkg2-4g34gwed",
        //       location_type:"Pharmacy",
        //       has_drug_register: true,
        //       parent: "Somewhere's parent"
        //     },
        //     drugs:[
        //         {
        //             uuid:"asidjeg-434g3ger-g23f2k3f32f",
        //             quantity:5,
        //             alias:{
        //               alias_id: "95bf12e8-d4a4-11eb-ab14-04d4c478fc6f",
        //               form: "mL",
        //               form_description: "mg/mL",
        //               measurement_unit: "each",
        //               name: "Podophyllin Compound APF (extemporaneous)",
        //               quantity: "25.0000",
        //               region: "Australia",
        //             }
        //         },
        //         {
        //             uuid:"asidjeg-434g3ger-g23f2k3f32f",
        //             quantity:5,
        //             alias:{
        //               alias_id: "95bf12e8-d4a4-11eb-ab14-04d4c478fc6f",
        //               form: "mL",
        //               form_description: "mg/mL",
        //               measurement_unit: "each",
        //               name: "Isomonit",
        //               quantity: "25.0000",
        //               region: "Australia",
        //             }
        //         },
        //     ],
        //     status: "pending",
        //     executor:{},
        //     signatures:[]
        // }

      }
    },
  },
  mounted() {
    this.loadDestructions();
    this.toCache = [];
  },
  computed: {
    cartonCloudRestriction() {
      // this is a special carton cloud integration restriction for warehouse
      // that restricts manual new purchase or sales
      // orders creation as per DNH requirements
      // to limit creation of extraneous invoices
      if (this.$store.state.user.integration_type === 'carton-cloud') {
        return (this.$store.state.user.integration_type === 'carton-cloud' && !this.$store.state.user.role.permissions.includes('can_do_admin_roles'));
      } else return false; // return false for a plain warehouse, warehouse minus carton cloud integration and aged care
    },
    getDrugEntries() {
      return this.$store.getters.getDrugEntries
    },
    numberToDestroy() {
      let num = 0;
      if (this.getDrugEntries) {
        this.getDrugEntries.forEach((item) => {
          if (item.display) {
            num += 1;
          }
        })

      }
      return num;

    },
    numberToReverse() {
      let num = 0;
      if (this.getDrugEntries) {
        this.getDrugEntries.forEach((item) => {
          if (item.display) {
            num += 1;
          }
        })
      }

      return num;
    }
  }
}
</script>


<style lang="scss">
@import "../../assets/css/main.scss";

.select-row {
  background-color: #E5F1FE; //$babyblue;
}

.pi-circle-on,
.pi-circle-off,
.pi-check-circle {
  color: #0870DC; //$strongblue;
  opacity: 50%;
  font-weight: bold;

}

.circle-on,
.circle-off,
.check-circle {

  font-weight: bold;
  width: 20px;
  height: 20px;
  border-radius: 20%;
  margin-left: 5px;
  border: 2px solid #0870DC; //$strongblue;
}

.circle-on {
  background-color: #0870DC; //$strongblue;
}

.destroy-button {
    color: white;
    background-color: #E04F39; //$error;
    font-weight: bold;
  }
</style>