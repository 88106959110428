<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 30%;">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">New {{$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}}</h3>
                        <button @click="closeModal" class="red-close-button">
                            <i class="pi pi-times" style='padding: 0'></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <!-- action="{{ url('payment') }}" -->
                        <!-- aria-label="{{ __('PaymentSubmit') }}" -->
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container">
                                <div class="left-form-section-panel" style="min-height: fit-content">
                                    <p @click="addToPending()"
                                        :class="[form.submit.pending || ispendingDestruct ? 'selected-hollow-button' : '']"
                                        :style="form.submit.pending || ispendingDestruct ? {color: 'white'}:{color: '#0870DC'}"
                                        style="padding: 3px 10px; height: 30px; width: 322px; border-radius: 8px; border: 2px solid #0870DC; cursor: pointer; margin-bottom: 5px; font-weight: bold;  ">
                                        {{ form.submit.pending || ispendingDestruct ? 'Adding to pending list' : 'Add to pending list?' }}
                                    </p>
                                    <div v-if="!form.submit.pending && !ispendingDestruct">
                                        <div>
                                            <p class="note-header">Reason for {{$store.state.company.type == 'warehouse' ?  'Destruction' : 'Disposal'}}</p>
                                            <textarea name="notes" id="note-modal" rows="14" cols="44"
                                            :placeholder="$store.state.company.type == 'warehouse' ?  'Enter reason for destruction here...' : 'Enter reason for disposal here...'"
                                            style="width: 100%; border-radius: 0px 0px 8px 8px;"
                                            v-model="form.submit.notes"></textarea>
                                        </div>
                                        <!-- METHOD -->
                                        <input v-model="form.submit.method" :placeholder="$store.state.company.type == 'warehouse' ?  'Method of Destruction' : 'Method of Disposal'"
                                            style="position: relative; width: 100%;margin-bottom: 5px" />
                                    
                                        <input v-model="form.submit.courier_name" placeholder="Courier Name"
                                                style="position: relative; width: 100%;margin-bottom: 5px; background-color: #E5F1FE;" />
                                        <input v-model="form.submit.courier_notes" placeholder="Courier Notes"
                                                style="position: relative; width: 100%;margin-bottom: 5px; background-color: #E5F1FE;" />
                                    </div>
                                </div>
                                <!-- RIGHT SIDE PANEL -->
                                <div class="right-form-section-panel" style="display: flex; flex-direction: column;">
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <!-- BEFORE THEY CHOOSE IMPREST OR PATIENT OWNED MEDS -->
                                    <div v-if="patient && patient.personal_information && patient.regulatory_information"
                                        class="individual-patient-container">
                                        <!-- <div class="patient-result-image" style="width: 100px; height: 100px; background-color: salmon">

                                </div> -->
                                        <div class="patient-result-info">
                                            <p><b>Name:</b> {{ patient.personal_information.first_name }}
                                                {{ patient.personal_information.last_name }}</p>
                                            <p v-if="patient.regulatory_information.length"><b>{{
                                                patient.regulatory_information[0].regulatory_type
                                            }}:</b>
                                                {{ patient.regulatory_information[0].data }}</p>
                                            <p><b>DOB:</b> {{
                                                patient.personal_information ?
                                                    patient.personal_information.dob : ''
                                            }}</p>
                                            <p><b>Location:</b> {{
                                                patient.physical_location &&
                                                    patient.physical_location.location ?
                                                    patient.physical_location.location.name : ''
                                            }}</p>
                                        </div>
                                    </div>



                                    <div v-if="!imprest_pom && !ispendingDestruct && $store.state.company.type !== 'warehouse'">
                                        <p
                                            style="margin: 0; padding: 0; padding-bottom: 10px; font-size: 0.95em; text-align: left;">
                                            Please select whether this is imprest/emergency meds/ward stock or resident medication.</p>
                                        <div class="right-form-section-drug-entry">
                                            <div class="pom-imprest-select">
                                                <p @click="() => { imprest_pom = 'pom'; displayPatientSearchModal = true }"
                                                    class="pom-imprest-choice-button"
                                                    v-bind:class="[imprest_pom == 'pom' ? 'active-select-filter' : '']">
                                                    Resident Medication</p>

                                                <p @click="() => { imprest_pom = 'imprest'; }"
                                                    class="pom-imprest-choice-button"
                                                    v-bind:class="[imprest_pom == 'imprest' ? 'active-select-filter' : '']"
                                                   >
                                                    Imprest/Emergency Meds/Ward Stock</p>

                                            </div>
                                        </div>
                                    </div>


                                    <!-- PATIENT MEDICATION SEARCH -->
                                    <div class="right-form-section-drug-entry" v-if="patient">
                                        <div style="width: 100%; margin-right: 10px">

                                            <select style="position: relative; width:100%; padding: 10px; "
                                                id="pom-select" @change="changeStock()">
                                                <option value="" disabled selected>Select medication</option>
                                                <option :value="index" v-for="(item, index) in patientMeds"
                                                    :key="index">{{ item.alias ? item.alias.name : '' }}
                                                    ({{ Number(item.quantity).toFixed(2) }} left)</option>
                                            </select>
                                        </div>

                                        <input v-model="qty" min="0" type="number" placeholder="Quantity"
                                            style="position: relative; width: 30%; margin-right: 10px;" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty) { aliasSelect(stock) } }">
                                            Add</p>
                                    </div>


                                    <!-- <div class="right-form-section-drug-entry" v-if="imprest_pom == 'imprest'">
                                        <div style="width: 40%;">
                                            <input v-model="stock" placeholder="Select Stock"
                                                style="position: relative; width:100%" />
                                        </div>

                                        <input v-model="qty" type="number" placeholder="Enter quantity"
                                            style="position: relative; width: 40%" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty) { checkAndAddStock(stock) } }">Add</p>
                                    </div>
                                    <div class="drug-variances-container" style="display: flex; height: fit-content"
                                        v-show='refinedDrugs.length'>
                                        <p style="padding: 3px 10px; margin-right: 5px;">Select stock: </p>
                                        <p v-for="(item, index) in refinedDrugs" :key="index" @click="aliasSelect(item)"
                                            style="padding: 3px 10px; height: fit-content; margin-right: 10px; border-radius: 8px; border: 2px solid green; cursor: pointer">
                                            {{ item.alias.name }} <span style="font-weight: bold">In Stock:
                                                {{ item.qty_left }}</span>
                                        </p>
                                    </div>
                                    <div v-if="Array.isArray(refinedDrugs) && refinedDrugs.length == 0">
                                        <p style="font-size: 1em;">No stock found for that item.</p>
                                    </div> -->

                                    <!-- MED DROP DOWN -->
                                    <!-- MED DROP DOWN -->
                                    <!-- MED DROP DOWN -->
                                    <div class="right-form-section-drug-entry" v-if="($store.state.company.type == 'warehouse' ? $store.state.company.type == 'warehouse' && imprest_pom == '' && !ispendingDestruct : imprest_pom == 'imprest' && !ispendingDestruct)">
                                        <div style="display: flex; align-items: center;">
                                            <!-- <Dropdown v-model="stock" ref="med" @change="drugSelect(stock), show()" @keyup="searchDrugs(stock), show()" :loading="loadingDrugs" :options="drugList" optionLabel="full_name" :editable="true"
                                            style="width: 25em; display: flex" optionDisabled="disabled" placeholder="Type medication name here"/> -->
                                            <!-- <Dropdown v-model="stock" ref="med" @change="drugSelect(stock)"
                                                @keyup="debounceSearchDrugs(stock), show()" :loading="loadingDrugs"
                                                :options="drugList" optionLabel="full_name" :editable="true"
                                                style="width: 25em; display: flex" placeholder="Select Medication" /> -->
                                                  <!-- <input id="medication" placeholder="Search a Supplier..." style="position: relative" /> -->
                                                    <Dropdown
                                                      v-model="stock"
                                                      ref="med"
                                                      @keyup="debounceSearchDrugs(stock), show()"
                                                      @change="drugSelect(stock)"
                                                      :loading="loadingDrugs"
                                                      :options="drugList"
                                                      optionLabel="full_name"
                                                      :editable="true"
                                                      style="width: 25em; display: flex"
                                                      placeholder="Select Medication" 
                                                    />
                                        </div>
                                        <input v-model="qty" min="0" type="number" placeholder="Enter qty"
                                            style="position: relative; width: 20%" />
                                        <p class="submit-button blue-button"
                                            @click="() => { if (qty) { checkIfExistingItem(selectedDrug.full_item) } }">
                                            Add</p>
                                        <!-- <p class="submit-button grey-button" style="background-color: black !important"
                                        @click="() => { if (qty) { imprest_pom = ''; stock=''; qty=''; refinedDrugs = []; patient = '' } }">Cancel</p> -->
                                    </div>


                                    <div class="right-form-section-drug-container" v-if="!ispendingDestruct">
                                        <table style="border-collapse: collapse">
                                            <tr v-for="(item, index) in form.submit.drugs" :key="index"
                                                class="drug-entry-transfer">
                                                <td style="width: 50%;">
                                                    <p>{{ item.stock.slice(0, 1).toUpperCase() + item.stock.slice(1) }}
                                                    </p>
                                                </td>
                                                <td v-if="$store.state.company.type == 'warehouse'">
                                                    <p style="width: max-content;">
                                                        {{ item.batch_number }}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p style="width: max-content;">
                                                        {{ item.qty }} {{
                                                            item.qty != 'None in stock.' &&
                                                                (item.form[item.form.length - 1] != 'y' && item.qty > 1) ?
                                                                item.form : ''
                                                        }}{{
    item.qty > 1 && (item.form[item.form.length - 1] != 'y') ?
        's' : (item.form[item.form.length - 1] == 'y' && item.qty > 1) ?
            item.form.slice(0, item.form.length - 1) + 'ies' : item.form
}}
                                                        (Stock qty:{{
                                                            item.qty_left ?
                                                                item.qty_left : 0
                                                        }})</p>
                                                </td>
                                                <td style="width: 40px;">
                                                    <div>
                                                        <p @click="removeDrug(index)"
                                                            class="red-close-button small-button">
                                                            <i class="pi pi-times" style='padding: 0'></i>
                                                        </p>
                                                    </div>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>
                                    <div class="right-form-section-drug-container" v-if="ispendingDestruct">
                                        <table style="border-collapse: collapse">
                                            <tr v-for="(item, index) in form.submit.drugs" :key="index"
                                                class="drug-entry-transfer">
                                                <td v-if="checkItemBoxView" style="min-width: 15px;">
                                                                <div @click.stop="item.selected = !item.selected" style="cursor: pointer">
                                                                <div v-if="item.selected && (item.selected == true)" class="check-circle circle-on"></div>
                                                                <div v-else class="circle-off"></div>
                                                               </div>
                                                             </td>
                                                <td style="width: 50%;">
                                                    <p>{{ item.stock }}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p>
                                                        {{ item.qty }}</p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </slot>
                </div>
                <p style="color: red; font-weight: 500; font-size: 1em;" v-if="errorMessage">{{ errorMessage }}</p>
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px;">
                    <slot name="footer" style="display: flex;">
                        <p class="regular-button error-button" style="color: white; margin-right: 10px;" @click="closeModal">Cancel
                        </p>
                         <!-- <p class="regular-button grey-button" @click="submitDestroyAndCreateNew()">Destroy
                            {{ form.submit.pending ? 'Later' : '' }} + New</p> -->
                        <p :class="buttonchangeUi()"  style="margin-right: 10px;" @click="submitDestroy()">
                            {{$store.state.company.type == 'warehouse' ?  'Destroy' : 'Dispose'}} {{ form.submit.pending ? 'Later' : '' }}</p>
                       
                    </slot>
                </div>
            </div>
        </div>
        <PatientSearchModal @close="close" v-if="displayPatientSearchModal" @patient="getPatient" />
        <!-- :witness="$store.state.currentLocation.state == 'WA'" -->
        <!-- :optional="form.submit.pending" -->
        <SignatureModal v-if="displaySignatureModal" :payload="realSubmit"  
            @isSuccessful="isSuccessful" :saveContext="saveContext" @close="close"
            :endpoint="'/location/' + $store.state.currentLocation.uuid + '/destroy'"
            :manual_loop="imprest_pom == 'imprest'  && $store.state.currentLocation.state == 'WA'"/>
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import PatientSearchModal from '@/components/modals/PatientSearchModal.vue';
import axios from 'axios';
import { debounce } from 'debounce';

export default {
    props: ['transferType','ispendingDestruct','checkItemBoxView','actionParentdata'],
    components: {
        // DualVerify,
        SignatureModal,
        PatientSearchModal
    },
    data() {
        return {
            witness: false,
            selectedDrug: {},
            drugList: [],
            loadingDrugs: false,
            imprest_pom: '',
            pom: '',
            patient: '',
            gp: '',
            prescriberResults: [],
            addNew: false,
            patientMeds: [],
            prescriber: '',
            selectedPrescriber: '',
            errorMessage: '',
            displaySignatureModal: false,
            displayPatientSearchModal: false,
            newLoc: '',
            stock: '',
            reasonCat: '',
            reasonSubCat: '',
            qty: null,
            location: '',
            addNewLocation: false,
            noLocationFound: false,
            refinedLocations: null,
            refinedDrugs: '',
            form: {
                type: 'destroy',
                submit: {
                    patient_uuid: '',
                    prescriber_uuid: '',
                    method: '',
                    notes: '',
                    pending: false,
                    drugs: [],
                    courier_name: '',
                    courier_notes: ''
                }
            },
            // INFORMATION PROVIDED TO USER TO PROVIDE CONTEXT ON AUTOSAVE INFORMATION
            saveContext: {
                type: 'Destruction',
                patient: '',
                location: '',
            s: [],
                doubleSig: false
            },
            timeout: 0
        }
    },
    methods: {
        show() {
            console.log('HITING SAVE FUNCTION');
            let interval = setInterval(() => {
                console.log('HITTING INTERVAL');
                if (!this.loadingDrugs && this.drugList) {
                    clearInterval(interval);
                    this.$refs.med.show();
                }
            }, 100)
        },
        debounceSearchDrugs: debounce(function() {
                this.searchDrugs()
        },[500]),
        drugSelect(item) {
            this.loadingDrugs = true;
            console.log('this is the drug item selected', item);
            this.selectedDrug.full_item = item;
            this.selectedDrug.drug_uuid = item.alias.alias_id;
            this.selectedDrug.full_name = item.full_name;
            this.selectedDrug.form = item.alias.form[0].toUpperCase() + item.alias.form.slice(1);
            this.selectedDrug.strength = item.alias.strength;
            this.selectedDrug.measurement_unit = item.alias.measurement_unit;
            this.selectedDrug.name = item.alias.name[0].toUpperCase() + item.alias.name.slice(1);
            this.selectedDrug.tags = item.alias.tags;
            this.loadingDrugs = false;
        },
        searchDrugs() {
            if (this.stock) {
                this.loadingDrugs = true;
                axios.post('/get-stock-items', { drug: this.stock }, this.$store.state.header).then(res => {
                    console.log('this is the res data getstockitems', res.data);
                    this.$store.dispatch('getRefreshToken');
                    res.data.forEach(item => {
                        //item.full_name = `(${item.alias.generic_name}) ${item.alias.name} [In stock: ${item.qty_left}]`;
                        if (this.$store.state.company.type === 'aged care') item.full_name = `(${item.alias.generic_name}) ${item.alias.name} [In stock: ${item.qty_left}]`;
                            // for warehouse
                            else item.full_name = `${item.alias.name} (Batch: ${item.batch_number ?? 'N/A'}) [In stock: ${item.qty_left}]`;
                        if (item.qty_left == 0) {
                            item.disabled = true;
                        } else {
                            item.disabled = false;
                        }
                    })
                    this.drugList = res.data;
                    this.errorMessage = '';
                    this.loadingDrugs = false;
                }).catch(err => {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                        console.log('yes, it does include expired')
                        this.$router.push('/login');
                    }
                    console.log('this is the transfer stock items error', err.response.data);
                    this.errorMessage = err.response.data;
                })
            }
        },
        checkIfExistingItem(item) {
            let exit = false;
            if (this.form.submit.drugs.length) {
                this.form.submit.drugs.forEach(drugItem => {
                    console.log('LOOPING THROUGH LIST', drugItem, item);
                    if (((item.uuid == drugItem.drug) || (item.alias_id == drugItem.drug)) && !exit) {
                        console.log('HIT A MATCH', drugItem);
                        drugItem.qty = this.qty;
                        exit = true;
                    } else if (!exit) {
                        this.aliasSelect(item);
                        exit = true;
                    }
                })
            } else {
                this.aliasSelect(item);
            }
        },
        // checkIfExistingItem(){
        //         const existDrug = this.form.submit.drugs.filter((item)=>{
        //         return item?.drug === this.stock?.uuid
        //     })
        //     if (existDrug?.length > 0) {
        //         this.errorMessage = "Duplicate medication selected"
        //         return ;
        //     }else{
        //         this.errorMessage = ''
        //         const algoliaDrug = {
        //             drug: this.stock?.uuid,
        //             medication: this.stock?.alias.name,
        //             qty: 0
        //         }
        //         if (this.stock?.uuid) {
        //             this.form.submit.drugs.push(algoliaDrug)
        //             this.addingMed = true
        //             if (this.addingMed) {
        //                 this.stock = null
        //             }
        //         }
        //     }
        // },
        changeStock() {
            this.stock = this.patientMeds[document.getElementById('pom-select').value];
            this.qty = Number(this.stock.quantity).toFixed(1);
        },
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        closeLocationModal(value) {
            if (value) {
                this.addNewLocation = false;
            }
        },
        close(value) {
            if (value) {
                this.witness = false;
                this.displaySignatureModal = false;
                if (this.imprest_pom != 'imprest' && !this.patient) {
                    this.displayPatientSearchModal = false;
                    this.imprest_pom = '';
                }
            }
        },
        isSuccessful(value) {
            if (value) {
                if (window.location.pathname == '/stock/destructions') {
                    this.$store.dispatch('getDestructionItems', {
                        sort_direction: '',
                        col: '',
                        page: 1,
                        location: this.$store.state.uuid,
                        filters: {
                            status: this.form.submit.pending || this.ispendingDestruct ? "pending" : "completed",
                            drug: '',
                            date: {
                                start: '',
                                end: ''
                            }
                        }
                    })
                }
                if (this.addNew) {
                    this.form.submit.drugs = [];
                    this.imprest_pom = '';
                    this.patient = '';
                    this.patient_uuid = '';
                    this.prescriber_uuid = '';
                    this.selectedPrescriber = '';
                } else {
                    this.$emit('close', true, this.form.submit.pending || this.ispendingDestruct ? "pending" : "completed");
                }
            }

        },
        addToPending() {
            if(!this.ispendingDestruct){
                if (this.form.submit.pending) {
                 this.form = {
                    type: 'destroy',
                    submit: {
                        patient_uuid: '',
                        prescriber_uuid: '',
                        method: '',
                        notes: '',
                        pending: this.form.submit.pending,
                        drugs: this.form.submit.drugs,
                        courier_name: '',
                        courier_notes: ''
                    }
                }
            }
                this.form.submit.pending = !this.form.submit.pending;
            }
        },
        addToReason(reason) {
            this.form.submit.reason = reason;
            this.reasonCat = '';
            console.log('DOES IT CONTAIN RETURN STOCK TO PHARM?', this.form.submit.reason.includes('Return Stock to Pharmacy'))
            if (this.form.submit.reason == 'Damaged or Expired Stock') {
                this.reasonCat = '';
                this.reasonSubCat = '';
            } else {
                this.reasonCat = 'Return Stock to Pharmacy';
            }
            if (reason == 'From Doctor' || reason == 'From Resident') {
                this.form.submit.reason = 'Return Stock to Pharmacy -' + reason
                this.reasonSubCat = reason;
            }
        },
        aliasSelect(item) {
            console.log('this is the alias select item', item);
            // if((this.imprest_pom == 'imprest')){
            // axios.post('/get-stock', {alias_id:item.alias_id, location_id: this.$store.state.uuid }, this.$store.state.header).then(res=>{

            //         console.log('this is the quantity if condition',res, this.qty, res.data.qty_left, this.qty > res.data.qty_left);
            //         this.form.submit.drugs.unshift({tags: res.data.tags, stock: res.data.alias.name, form:res.data.alias.form, drug:res.data.uuid, qty_left: res.data.qty_left, qty: this.qty <= Number(res.data.qty_left) ? Number(this.qty).toFixed(1) : Number(res.data.qty_left).toFixed(1)});

            //     this.$store.dispatch('getRefreshToken');
            //     this.qty = null;
            //     this.refinedDrugs = [];
            //     this.noStock = false;
            // }).catch(err=>{
            //     console.log('err is', err.response.data);
            //     if(err.response.data == 'No stock has been recorded on this location for that drug alias.'){
            //         console.log('hitting the if condition');
            //         this.form.submit.drugs.unshift({tags: item.tags, stock:item.name, drug: item.alias_id, form: item.form, qty:'None in stock.', qty_left: 0});
            //     }
            //     this.qty = null;
            //     this.refinedDrugs = [];
            // })} else{
            //     this.form.submit.drugs.unshift({tags: item.tags, stock: item.name, form:item.form, qty_left:item.quantity, drug:item.uuid, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity)});
            // }

            if ((this.imprest_pom == 'imprest' || this.imprest_pom == '')) {
                this.form.submit.drugs.unshift({ tags: item.alias.tags, stock: item.alias.name, drug: item.uuid, form: item.alias.form, qty: this.qty, qty_left: item.qty_left, batch_number: item.batch_number });
                this.qty = null;
                this.refinedDrugs = '';
                this.noStock = false;
                this.loadingDrugs = false;
                this.stock = '';
                this.selectedDrug = {};
                this.addingMed = false;
            } else {
                this.form.submit.drugs.unshift({ tags: item.alias ? item.alias.tags : '', stock: item.alias.name, form: item.alias.form, qty_left: item.quantity, drug: item.uuid, qty: this.qty <= Number(item.quantity) ? this.qty : Number(item.quantity), batch_number: item.batch_number });
            }
        },
        
        removeDrug(index) {
            console.log('this is the index', index, this.form.submit.drugs);
            this.form.submit.drugs.splice(index, 1);
        },
        buttonchangeUi(){
            if (this.$store.state.company.type == 'warehouse') {
                
                const selectData1 = this.form.submit.drugs.filter((item)=> item?.selected && item.selected === true)
                if (this.checkItemBoxView) {
                    if (this.ispendingDestruct) {
                        if (selectData1?.length > 0) {
                        return 'regular-button green-button'
                    }
                    else{
                        return 'regular-button grey-button'
                    }
                    }else{
                        if (selectData1?.length > 0 && this.form.submit.drugs && this.form.submit.notes && this.form.submit.courier_name && this.form.submit.courier_notes && this.form.submit.method) {
                        return 'regular-button green-button'
                    }
                    else{
                        return 'regular-button grey-button'
                    }
                    }
                }else{
                    if (this.form.submit.pending) {
                        if(this.form.submit.drugs?.length > 0){
                        return 'regular-button green-button'
                    }
                    else{
                        return 'regular-button grey-button'
                    }
                    }else{
                    if(this.form.submit && this.form.submit.notes && this.form.submit.courier_name && this.form.submit.courier_notes && this.form.submit.drugs?.length > 0 && this.form.submit.method){
                        return 'regular-button green-button'
                    }
                    else{
                        return 'regular-button grey-button'
                    }
                }
                }
            }else{
                return 'regular-button green-button'
            }
        },
        submitDestroy() {

            // this.$store.dispatch('submitForm',this.form);
            // let testID = "c309671f-2c62-3b0e-8088-4f0bd851f0e5";
            let newDrugSub = [];
            // LOCAL VARIABLE FOR DRUG CONTEXT AUTOCACHE
            let autoDrugs = [];
            this.addNew = false;
            if (this.ispendingDestruct) {
                const filterdata = this.form.submit.drugs.filter((item)=> item?.selected && item.selected === true)
                filterdata.forEach(item => {
                    let p = {
                        drug: item.drug,
                        qty: Number(item.qty),
                        alias:  item.stock,
                        tags: item?.tags,
                        batch_uuid: item?.batch_uuid,
                        batch_item_id:item?.batch_item_id
                    }
                    let ad = {
                        drug: item.stock,
                        qty: Number(item.qty),
                        tags: item?.tags,
                        batch_uuid: item?.batch_uuid,
                        batch_item_id:item?.batch_item_id
                    }
                    autoDrugs.push(ad);
                    newDrugSub.push(p);
                    if (item.tags && item.tags.length) {
                        this.witness = true;
                    }
                });
            }else{
                this.form.submit.drugs.forEach(item => {
                    let p = {
                        drug: item.drug,
                        qty: Number(item.qty),
                        alias:  item.stock,
                        tags: item.tags
                    }
                    let ad = {
                        drug: item.stock,
                        qty: Number(item.qty),
                        tags: item.tags
                    }
                    autoDrugs.push(ad);
                    newDrugSub.push(p);
                    if (item.tags && item.tags.length) {
                        this.witness = true;
                    }
                });
            }
            this.realSubmit = {
                drugs: newDrugSub,
                notes: `${this.ispendingDestruct ? true : this.form.submit.pending ? '' : this.$store.state.company.type == 'warehouse' ?  'Reason for Destruction: ' : 'Reason for Disposal: ' + this.form.submit.notes}` +  ` ${this.ispendingDestruct ? true : this.form.submit.pending ? '': '-- Method of Disposal: '+this.form.submit.method}. ${this.ispendingDestruct ? true : this.form.submit.pending ? ' Stock quarantined in safe awaiting disposal':''}`,
                method: this.form.submit.method,
                pending: this.ispendingDestruct ? true : this.form.submit.pending,
                prescriber_uuid: this.selectedPrescriber ? this.selectedPrescriber.uuid : '',
                patient_uuid: this.patient ? this.patient.uuid : '',
                // patient_uuid: this.patient ? testID : '',
                courier_name: this.form.submit.courier_name,
                courier_notes: this.form.submit.courier_notes
            };
            this.saveContext = {
                drugs: autoDrugs,
                location: JSON.parse(localStorage.getItem('currentLocation')).name,
                patient: this.patient ? `${this.patient.personal_information.first_name} ${this.patient.personal_information.last_name}` : 'Imprest/Emergency Stock',
                supplemental: this.patient && this.patient.regulatory_information.length ? `${this.patient.regulatory_information[0].regulatory_type}: ${this.patient.regulatory_information[0].data}` : ``,
                doubleSig: this.witness ? true : false,
                notes: this.form.submit.notes,
                courier_name: this.form.submit.courier_name,
                courier_notes: this.form.submit.courier_notes,
                method: this.form.submit.method,
                type: 'Destruction'
            }
            // this.realSubmit.drugs = newDrugSub;
            console.log('this is the form to submit', this.form, newDrugSub);
            console.log('this is the SAVE CONTEXT', this.saveContext);
            console.log('DRUG SUB', newDrugSub);
            console.log(this.form.submit.drugs.length && this.form.submit.notes);
            if (this.ispendingDestruct) {
                if (this.realSubmit?.drugs.length > 0) {
                    this.displaySignatureModal = true;
                    this.errorMessage = '';
                } else {
                    this.errorMessage = 'Medications field are required.'
                }
            }else{
                if (this.$store.state.company.type == 'warehouse') {
                    if (this.form.submit.pending) {
                        if (this.form.submit.drugs.length) {
                            this.displaySignatureModal = true;
                            this.errorMessage = '';
                        } else {
                            this.errorMessage = 'Medications field are required.'
                        }
                    } else {
                        if (this.form.submit.drugs.length && this.form.submit.notes) {
                            this.displaySignatureModal = true;
                            this.errorMessage = '';
                        } else {
                            this.errorMessage = 'Medications, reason for destruction and method fields are required.'
                        }

                    }
                } else {
                    this.displaySignatureModal = true;
                    this.errorMessage = '';
                }
            }
            this.refinedLocations = [];
        },
        selectPrescriber(prescriber) {
            console.log('this is the selected result', this.nonGP);
            this.prescriberResults = [];
            this.selectedPrescriber = {
                prescriber_no: prescriber.regulatory_numbers.length ? prescriber.regulatory_numbers[0].data : '',
                uuid: prescriber.uuid,
                first_name: prescriber.first_name,
                last_name: prescriber.last_name,
                phone: prescriber.phone,
                after_hours: prescriber.after_hours,
                address: prescriber.address,
                email: prescriber.email,
                fax: prescriber.fax,
                primary_gp: false
            }
        },
        changePrescriber() {
            this.selectedPrescriber = '';
            this.gp = '';
        },
        getPatient(value) {
            if (value) {
                this.patient = value;
                this.displayPatientSearchModal = false;
                axios.post(`/person/${this.patient.uuid}/stock-register`, {}, this.$store.state.header).then(res => {
                    console.log('this is the patient register', res);
                    this.patientMeds = res.data;
                })
            }
        },
        submitDestroyAndCreateNew() {
            if (this.form.submit.drugs.length && this.form.submit.notes) {
                this.addNew = true;
                let newDrugSub = [];
                this.form.submit.drugs.forEach(item => {
                    let p = {
                        drug: item.drug,
                        qty: Number(item.qty)
                    }

                    if (item.qty > 0) {
                        newDrugSub.push(p);
                    }
                });
                this.realSubmit = {
                    drugs: newDrugSub,
                    notes: this.form.submit.notes,
                    method: this.form.submit.method,
                    pending: this.form.submit.pending,
                    prescriber_uuid: this.selectedPrescriber ? this.selectedPrescriber.uuid : '',
                    patient_uuid: this.patient ? this.patient.uuid : '',
                    courier_name: this.form.submit.courier_name,
                    courier_notes: this.form.submit.courier_notes
                };
                // this.$store.dispatch('submitDelivery',this.form);
                // this.refinedLocations = [];

                this.displaySignatureModal = true;
                this.errorMessage = '';
                this.stock = '';
            } else {
                this.errorMessage = 'Drugs and notes are required.'
            }
        },
        //     gpSearch(prescriber) {
        //         let headers = {
        //             "X-Algolia-API-Key": this.$store.state.VUE_APP_ALGOLIA_API_KEY,
                // "X-Algolia-Application-Id": this.$store.state.VUE_APP_ALGOLIA_APP_ID
        //         }
        //         // let results = [];
        //         this.prescriberResults = [];
        //         console.log('this is the drug', prescriber);
        //         // https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/${this.$store.state.subdomain}_prescribers/browse
        //         axios.post(`https://H4AK5A0Y5K-dsn.algolia.net/1/indexes/*/queries`, 
        //         // params: `query=${prescriber}&hitsPerPage=5`,
        //         { 
        //           requests:[
        //             { "indexName": `${this.$store.state.subdomain}_prescribers`, "params":  `query=${prescriber}` },
        //             { "indexName": `${this.$store.state.subdomain}_prescriber_references`, "params":  `query=${prescriber}` },
        //           ]  
        //         },
        //             { headers }).then(res => {
        //                 console.log('this is the algolia res', res.data.results);
        //                 res.data.results.forEach(indexResult=>{
        //                     console.log('these are the results for this particular index', indexResult);
        //                     if(indexResult.hits.length){

        //                         // results.push(indexResult.hits);
        //                         indexResult.hits.forEach(hit=>{
        //                             this.prescriberResults.push(hit);
        //                         })
        //                         console.log('this is the local results varaible', this.prescriberResults);
        //                     }

        //                 })
        //                 // this.prescriberResults = res.data.hits;
        //                 // state.commit('setDrugNames', res.data.hits);
        //             })

        //    },
    

},
mounted() {
    if (this.actionParentdata?.items) {
            if (this.actionParentdata?.items?.length > 0) {
                this.form.submit.drugs = this.actionParentdata?.items
                this.addingMed = true
                return this.actionParentdata?.items
            }
        }
}
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

 

  


.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.right-form-section-panel {
    padding: 0 20px;
    overflow: auto !important;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}
</style>